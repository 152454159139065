import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications';

//components
import Home from './Pages/Home'
import Redirect from './Pages/Redirect'

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Switch>
          <Route exact path="/" render={() => (<Home />)} />
          <Route path="/redirect" render={() => (<Redirect />)} />
        </Switch>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
